import { Coordinate } from "ol/coordinate";
import MVT from "ol/format/MVT";
import { Point } from "ol/geom";
import "ol/ol.css";
import { fromLonLat, toLonLat } from "ol/proj";
import { useEffect, useState } from "react";

import { Feature, MapBrowserEvent } from "ol";
import GeoJSON from "ol/format/GeoJSON";
import { Stroke, Style } from "ol/style";
import { RFeature, RLayerTile, RLayerVector, RLayerVectorTile, RMap, ROSM, RStyle } from "rlayers";
import { RView } from "rlayers/RMap";
import { urlVectorTiles } from "../../environments";
import { UnidadeParcelar } from "../../interfaces";
import { getLoteById } from "../../services/api";
import locationIcon from "./../../assets/location.svg";

interface MapProps {
  setCoordenadas: (coordenadas?: Coordinate) => void;
  unidadeSelecionada?: UnidadeParcelar;
}

export function Map(props: MapProps): JSX.Element {
  const [localization, setLocalization] = useState<Coordinate>();
  const [lotes, setLotes] = useState<Feature[]>([]);
  const [view, setView] = useState<RView>({ center: [-4709331.63393486, -1390142.1895435604], zoom: 7 });

  useEffect(() => {
    if (props.unidadeSelecionada) {
      fetchSelectedLote().then((lotes) => {
        setLotes(lotes);
        setView({
          center: lotes[0].getGeometry()!.getExtent(),
          zoom: 15,
        });
      });
    }
  }, []);

  async function fetchSelectedLote() {
    const lote = (await getLoteById(Number(props.unidadeSelecionada?.id_lote))).data;

    const features: Feature[] = [];

    const feature = new GeoJSON().readFeature(lote.geom, {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    });

    feature.setId(lote.id);
    feature.setProperties({
      ...feature.getProperties(),
      ...lote,
      offline: true,
    });

    features.push(feature);

    return features;
  }

  function handleMapClick(this: RMap, e: MapBrowserEvent<UIEvent>) {
    const point = e.map.getCoordinateFromPixel(e.pixel);
    const lonLat = toLonLat(point);
    setLocalization(fromLonLat(lonLat));
    props.setCoordenadas(lonLat);
  }

  return (
    <RMap width={"100%"} height={"100%"} initial={view} view={[view, setView]} onClick={handleMapClick}>
      <ROSM zIndex={1} />

      <RLayerTile
        url={`https://apps.geo360.com.br/dados/codevasf/rastertile/2022/S3_SR06-Lote05/Geral/{z}/{x}/{y}.webp`}
        zIndex={10}
      />
      <RLayerTile
        url={`https://apps.geo360.com.br/dados/codevasf/rastertile/2022/S1_SR02_Lote02/Geral/{z}/{x}/{y}.webp`}
        zIndex={10}
      />
      <RLayerTile
        url={`https://apps.geo360.com.br/dados/codevasf/rastertile/2022/S2_SR03_Lote03/Geral/{z}/{x}/{y}.webp`}
        zIndex={10}
      />
      <RLayerTile
        url={`https://apps.geo360.com.br/dados/codevasf/rastertile/2023/S6_SR05-Lote04/Geral/{z}/{x}/{y}.webp`}
        zIndex={10}
      />
      <RLayerTile
        url={`https://apps.geo360.com.br/dados/codevasf/rastertile/2023/S5_SR04-Lote04/Geral/{z}/{x}/{y}.webp`}
        zIndex={10}
      />
      <RLayerTile
        url={`https://apps.geo360.com.br/dados/codevasf/rastertile/2023/S7_SR07-Lote06/Geral/{z}/{x}/{y}.webp`}
        zIndex={10}
      />
      <RLayerTile
        url={`https://apps.geo360.com.br/dados/codevasf/rastertile/2022/S4_SR01-Lote01/Geral/{z}/{x}/{y}.webp`}
        zIndex={10}
      />

      <RLayerVectorTile
        url={`${urlVectorTiles}/dados.vw_lote/{z}/{x}/{y}.pbf`}
        format={new MVT()}
        zIndex={100}
        style={[
          new Style({
            stroke: new Stroke({
              color: "#ffffff",
              width: 5,
            }),
          }),
          new Style({
            stroke: new Stroke({
              color: "#000000",
              width: 2,
            }),
          }),
        ]}
      ></RLayerVectorTile>

      <RLayerVector zIndex={101}>
        {lotes &&
          lotes.map((lote: any, index: number) => (
            <RFeature
              key={index}
              properties={lote.getProperties()}
              geometry={lote.geom}
              style={
                new Style({
                  stroke: new Stroke({
                    color: "#ffff00",
                    width: 5,
                  }),
                })
              }
            />
          ))}
      </RLayerVector>

      {localization && (
        <RLayerVector zIndex={998}>
          <RStyle.RStyle>
            <RStyle.RIcon src={locationIcon} anchor={[0.5, 0.8]} />
          </RStyle.RStyle>
          <RFeature geometry={new Point(localization)} />
        </RLayerVector>
      )}
    </RMap>
  );
}
