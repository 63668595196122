import GeoJSON from "ol/format/GeoJSON";
import { DadosCadastro, DadosEmail } from "../interfaces";
import { api } from "../repositories/api";

export async function login(email: string, senha: string) {
  try {
    const login = await api.post("/user/login", {
      username: email,
      password: senha,
    });
    localStorage.setItem("token", login.data.token);

    return login.data;
  } catch (error: any) {
    if (error.message.includes("400")) {
      return error.response.data.message;
    } else if (error.message.includes("500")) {
      return "Erro ao realizar login. Tente novamente mais tarde.";
    } else {
      return "Ocorreu um erro desconhecido. Por favor, tente novamente mais tarde.";
    }
  }
}

export async function cadastrarUsuario(dadosCadastro: DadosCadastro) {
  try {
    const response = await api.post("/user/cadastrar", dadosCadastro);
    return response.data;
  } catch (error: any) {
    if (error.message.includes("422")) {
      return "Erro ao cadastrar usuário. Verifique os dados inseridos e tente novamente.";
    } else {
      if (error.response.data.message) {
        return error.response.data.message;
      }
      return "Erro ao cadastrar usuário. Tente novamente mais tarde.";
    }
  }
}

export async function getUnidadeParcelarByCpfCnpj(cpfcnpj: string) {
  try {
    const response = await api.get(`/unidade-parcelar/` + cpfcnpj);
    return response;
  } catch (error: any) {
    return error.message;
  }
}

export async function getByUserByToken() {
  try {
    const response = await api.get(`/user/`);
    return response;
  } catch (error: any) {
    return error.message;
  }
}

export async function getAllUsers() {
  try {
    const response = await api.get(`/user/all`);
    return response;
  } catch (error: any) {
    return error.message;
  }
}

export async function getUserVerifyAdminByToken() {
  try {
    const response = await api.get(`/user/admin`);
    return response.data;
  } catch (error: any) {
    return error.message;
  }
}

export async function getAllDominios() {
  try {
    return await api.get(`/dominio/all`);
  } catch (error: any) {
    return error.message;
  }
}

export async function putLiberarUser(id: number, password?: string) {
  try {
    return await api.put(`/user/liberar/${id}`, { password: password });
  } catch (error: any) {
    return error;
  }
}

export async function putBlockUser(id: number) {
  try {
    return await api.put(`/user/bloquear/${id}`);
  } catch (error: any) {
    return error.response;
  }
}

export async function postEmail(dadosEmail: DadosEmail) {
  try {
    return await api.post(`/email`, dadosEmail);
  } catch (error: any) {
    return error.response;
  }
}

export async function getUnidadeParcelarById(id_lote: number) {
  try {
    return await api.get(`/unidade-parcelar/lote/` + id_lote);
  } catch (error: any) {
    return error.response;
  }
}

export async function putUnidadeParcelarById(id: number, dados: any) {
  try {
    return await api.put(`/unidade-parcelar/` + id, dados);
  } catch (error: any) {
    return error.response;
  }
}

export async function postUnidadeParcelar(dados: any) {
  try {
    return await api.post(`/unidade-parcelar`, dados);
  } catch (error: any) {
    return error.response;
  }
}

export async function putDadosPessoaisById(id: number, dados: any) {
  try {
    return await api.put(`/user/` + id, dados);
  } catch (error: any) {
    return error.response;
  }
}

export async function getLoteById(id: number) {
  try {
    return await api.get(`/lote/` + id);
  } catch (error: any) {
    return error.response;
  }
}
